<template>
  <div class="div-form-main" v-loading="formLoading">
    <div class="div-form-main-btn">
      <el-button
        v-if="$attrs.isFinancier &&  nftFormData.status !== FINANCE_STATUS[0] &&  nftFormData.status !== FINANCE_STATUS[4]"
        round
        type="primary"
        style="min-width: 100px"
        size="small"
        @click="isOpenSendEmailDialog = true"
      >
        Send Reminder Email
      </el-button>
      <el-button
        v-if="$attrs.isFinancier &&  nftFormData.status === FINANCE_STATUS[0]"
        round
        type="success"
        style="min-width: 100px"
        size="small"
        @click="() => $emit('handleApprove',true)"
      >
        Approve
      </el-button>
      <el-button
        v-if="$attrs.isFinancier &&  nftFormData.status === FINANCE_STATUS[0]"
        round
        type="danger"
        style="min-width: 100px"
        size="small"
        @click="() => $emit('handleApprove',false)"
      >
        Reject
      </el-button>
      <el-button
        round
        type="primary"
        style="min-width: 100px"
        size="small"
        v-if="
          [FINANCE_VIEW.CREATE_FINANCE, FINANCE_VIEW.EDIT_FINANCE].includes(view) && (!$attrs.isFinancier && nftFormData.status!==FINANCE_STATUS[4]) || $attrs.isFinancier && [FINANCE_STATUS[2],FINANCE_STATUS[3]].includes(nftFormData.status)
        "
        @click="onSubmit"
        >Submit</el-button
      >

      <el-button
        round
        type="primary"
        style="min-width: 100px"
        size="small"
        v-if="$attrs.isFinancier &&  nftFormData.status === FINANCE_STATUS[3]"
        @click="isOpenCompleteDialog = true"
        >Complete</el-button
      >
      <el-button
        round
        type="second"
        style="min-width: 100px"
        size="small"
        @click="onCloseFormPage"
        >Close</el-button
      >
    </div>
    <div class="div-form-main-container">
      <div class="el-card-is-always-shadow" style="padding: 20px">
        <div class="step-container">
          <el-steps
            :active="activeStepName"
            align-center
            finish-status="success"
          >
            <el-step
              v-for="item in FINANCE_STEPS"
              :description="item.label"
              :key="item.name"
            />
          </el-steps>
        </div>
        <div style="width: 100%; padding-top: 10px">
          <div class="cus-tab-container">
            <div
              class="tab-item-nav"
              :style="getTabStyle(item.name)"
              v-for="(item, index) in documentTabs"
              :key="index"
              @click="handleClickTab(item.name)"
            >
              {{ item.label }}
            </div>
          </div>
          <request-info-form
            v-if="!formLoading"
            ref="requestChildForm"
            v-bind="$attrs"
            :style="{
              display: activeDocUploadTab === 'REQUEST_DOC' ? 'block' : 'none',
            }"
            :view="view"
            :status="nftFormData.status"
            :nftFormData.sync="nftFormData"
            @setRemarksEditFlag="setRemarksEditFlag"
            @onCalculateSummary="onCalculateSummary"
            v-on="$listeners"
            @populateFormData="populateFormData"
          />
          <other-info-form
            v-if="!formLoading"
            ref="otherChildForm"
            v-bind="$attrs"
            :style="{
              display: activeDocUploadTab === 'OTHER_DOC' ? 'block' : 'none',
            }"
            :view="view"
            :status="nftFormData.status"
            :nftRespDetails="nftRespDetails"
            :nftFormData.sync="nftFormData"
            :isReceivedInfoChanged.sync="isReceivedInfoChanged"
            @populateFormData="populateFormData"
          />
        </div>
      </div>
      <div class="div-form-main-right-summary-border-none">
        <right-financer-info
          v-if="!formLoading && !rightSummaryLoading"
          v-bind="$attrs"
          :isViewOnly="isView || [FINANCE_STATUS[1],FINANCE_STATUS[2],FINANCE_STATUS[3],FINANCE_STATUS[4]].includes(nftFormData.status) || $attrs.isFinancier"
          :selectedFinancers.sync="nftFormData.pendingFinanciers"
          :isOpenSelectFinancerDialog.sync="isOpenSelectFinancerDialog"
          :rightSummaryLoading.sync="rightSummaryLoading"
          :status="nftFormData.status"
          :view="view"
          :financier="nftFormData.financier"
          :supplier="nftFormData.supplier"
          @onCalculateSummary="onCalculateSummary"
          :summaryList.sync="nftFormData.summaryList"
          :nftRespDetails="nftRespDetails"
        />
      </div>
    </div>
    <dialog-link-financer
      :isVisited="isOpenSelectFinancerDialog"
      :selectedFinancers.sync="nftFormData.pendingFinanciers"
      @onCalculateSummary="onCalculateSummary"
      @onCloseFinancerDialog="isOpenSelectFinancerDialog = false"
    />
    <dialog-complete-finance
      :dialogVisible="isOpenCompleteDialog"
      :entityData="nftFormData"
      @onCloseFinancerDialog="isOpenCompleteDialog = false"
      @getSubmitRespMessage="getSubmitRespMessage"
    />
    <dialog-send-reminder-email
      ref="sendEmailDialog"
      :dialogVisible="isOpenSendEmailDialog"
      :defaultEmail='nftFormData.emails'
      title="Send Reminder Email"
      @handleSendReminderDialogClose="onCloseSendReminderDialog"
      @handleSendEmail="handleSendEmail"

    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  FINANCE_VIEW,
  FINANCE_STATUS,
  FINANCE_STEPS
} from '@/constants.js'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import { getFinancingInvoiceById, uploadSupportingDocuments, uploadMtOneZeroThreeDocuments, updateFinancingInvoice, previewFeesAndCharges, sendReminderEmail, createOrUpdateAccountPayable } from '@/services/modules/finance.js'
import RightFinancerInfo from './RightFinancerInfo.vue'
import DialogLinkFinancer from './DialogLinkFinancer.vue'
import DialogCompleteFinance from './DialogCompleteFinance.vue'
import RequestInfoForm from './RequestInfoForm.vue'
import OtherInfoForm from './OtherInfoForm.vue'
import DialogSendReminderEmail from './DialogSendReminderEmail.vue'
export default {
  name: 'FinanceForm',
  components: {
    RightFinancerInfo,
    DialogLinkFinancer,
    RequestInfoForm,
    OtherInfoForm,
    DialogCompleteFinance,
    DialogSendReminderEmail
  },
  mixins: [baseMixin, baseTableMixin],
  props: {
    view: String
  },
  data () {
    const documentTabs = [
      {
        label: 'Request Information',
        name: 'REQUEST_DOC',
        hasNew: false
      },
      {
        label: 'Other Supporting Documents',
        name: 'OTHER_DOC',
        hasNew: true
      }
    ]
    const docListNames = ['requestInvoiceDocuments', 'orderDocuments', 'ebdnDocuments', 'noticeOfAssignmentDocuments', 'supportInvoiceDocuments', 'otherDocuments', 'mtOneZeroThreeDocuments']
    return {
      FINANCE_VIEW,
      FINANCE_STATUS,
      FINANCE_STEPS,
      documentTabs,
      activeDocUploadTab: documentTabs[0].name,
      formLoading: false,
      rightSummaryLoading: false,
      isOpenFilePreview: false,
      isOpenSelectFinancerDialog: false,
      isOpenCompleteDialog: false,
      nftRespDetails: {},
      previewFile: {},
      nftFormData: {
        requestInvoiceDocuments: [],
        ebdnDocuments: [],
        orderDocuments: [],
        remarks: null,
        referenceNo: '',
        deliveryDate: null,
        amount: null,
        paymentDays: null,
        withRecourse: null,
        accountPayableId: null,
        accountPayable: null,
        companyName: '',
        personInChargeName: '',
        companyAddress: '',
        contactNumber: '',
        emails: '',
        noticeOfAssignmentDocuments: [],
        otherDocuments: [],
        supportInvoiceDocuments: [],
        mtOneZeroThreeDocuments: [],
        pendingFinanciers: [],
        financier: null,
        supplier: null,
        status: null,
        amountReceivables: [],
        summaryList: []
      },
      docListNames,
      requestRemarksEditFlag: false,
      isOpenSendEmailDialog: false,
      isReceivedInfoChanged: false
    }
  },
  computed: {
    ...mapState(['financeAccountMe']),
    isView () {
      // return this.FINANCE_VIEW.VIEW_FINANCE === this.view
    },
    activeStepName () {
      const index = FINANCE_STEPS.findIndex((item) => item.name === this.nftFormData.status) + 1
      return index
    }
  },
  created () {
    this.fileUploadUrl = this.$apis.baseUrl + this.$apis.uploadFile
    if (this.view === this.FINANCE_VIEW.CREATE_FINANCE) {
    } else {
      this.populateFormData()
    }
  },

  methods: {
    populateFormData (resFormId) {
      if (this.$attrs.formId || resFormId) {
        const _formId = resFormId ?? this.$attrs.formId
        this.formLoading = true
        getFinancingInvoiceById(_formId).then((resp) => {
          this.formLoading = false
          if (resp.code === 1000) {
            const nftRespDetails = resp?.data ?? {}
            this.nftRespDetails = nftRespDetails
            const formFieldNames = Object.keys(this.nftFormData)
            this.nftFormData = { ...this.$_.pick(nftRespDetails, formFieldNames) }
            this.nftFormData.id = nftRespDetails.id
            this.nftFormData.accountPayableId = nftRespDetails.accountPayable.id
            this.nftFormData.companyName = nftRespDetails?.accountPayable?.companyName ?? ""
            let emailsStr = ''
            if (nftRespDetails?.accountPayable?.emails) {
              nftRespDetails.accountPayable.emails.forEach(item => {
                emailsStr = `${item.email};${emailsStr}`
              })
            }
            this.nftFormData.emails = emailsStr
            this.$set(this.nftFormData.supplier, 'requesterName', nftRespDetails.requesterName)
            if (nftRespDetails.financier) {
              this.nftFormData.summaryList = [{
                name: nftRespDetails.financier.name,
                paymentDays: nftRespDetails.paymentDays,
                amount: nftRespDetails.amount,
                discountRatePercentage: nftRespDetails.discountRatePercentage,
                amountReceivableAfterDiscountRate: nftRespDetails.amountReceivableAfterDiscountRate,
                lateChargesPercentage: nftRespDetails.lateChargesPercentage,
                lateChargesFeeBasedOnAmount: nftRespDetails.lateChargesFeeBasedOnAmount,
                platformFeePercentage: nftRespDetails.platformFeePercentage,
                platformFee: nftRespDetails.platformFee,
                id: nftRespDetails.status !== FINANCE_STATUS[0] ? nftRespDetails.financier.id : null
              }]
            } else {
              this.onCalculateSummary(this.nftFormData)
            }
          } else {
            this.getSubmitRespMessage(resp.message)
          }
        })
      }
    },
    getTabStyle (tabName) {
      if (this.activeDocUploadTab === tabName) {
        return { color: '#4C565C', fontWeight: 500, borderColor: '#30619E' }
      }
    },
    handleClickTab (active) {
      this.activeDocUploadTab = active
    },
    getSubmitRespMessage (errorMsg) {
      if (errorMsg) {
        this.$message({
          type: 'error',
          message: `Submit failed!
${errorMsg}`
        })
      } else {
        this.$message({
          type: 'success',
          message: 'Update successful!'
        })
        this.onRefreshFormPage()
      }
    },
    reqUpdateFinancingInvoice (payload) {
      updateFinancingInvoice(payload, this.$attrs.formId).then(resp => {
        if (resp.code === 1000) {
          this.getSubmitRespMessage()
        } else {
          this.getSubmitRespMessage(resp.message)
        }
      })
    },
    submitUpdateForm () {
      this.$refs.requestChildForm.$refs.reqestForm.validate((valid) => {
        if (valid) {
          const payload = { ...this.nftFormData, ...this.$refs.requestChildForm.financeRequestForm }
          this.docListNames.forEach(docListName => {
            if (payload[docListName]?.length > 0) {
              payload[docListName] = payload[docListName].map(item => {
                return { id: item.id, remarks: item.remarks }
              })
            }
          })
          payload.financierIds = payload.financier ? [payload.financier.id] : (payload.pendingFinanciers.map(financier => financier.id))
          // payload.withRecourse = payload.withRecourse === 1 ? true : false
          if (this.$refs.requestChildForm.isUpdatePayableAccount || this.$refs.requestChildForm.isCreateNewPayableAccount) {
            createOrUpdateAccountPayable(payload, payload.accountPayableId).then(res => {
              if (res?.code === 1000) {
                payload.accountPayableId = res.data.id
                this.reqUpdateFinancingInvoice(payload)
              }
            })
          } else {
            this.reqUpdateFinancingInvoice(payload)
          }
        }
      })
    },
    onSubmit () {
      switch (this.nftFormData.status) {
        case FINANCE_STATUS[0]:
          this.submitUpdateForm()
          break
        case FINANCE_STATUS[1]:
          const isUploadSupportingDocuments = this.nftFormData.noticeOfAssignmentDocuments.length > 0 && this.nftFormData.supportInvoiceDocuments.length > 0
          const onlyUpdatetRemarks = this.nftFormData.noticeOfAssignmentDocuments.length === 0 && this.nftFormData.supportInvoiceDocuments.length === 0
          if (isUploadSupportingDocuments) {
            /* upload other supporting documents by bunker */
            uploadSupportingDocuments({
              id: this.nftFormData.id,
              noticeOfAssignmentDocuments: this.nftFormData.noticeOfAssignmentDocuments,
              otherDocuments: this.nftFormData.otherDocuments,
              supportInvoiceDocuments: this.nftFormData.supportInvoiceDocuments
            }).then(resp => {
              if (resp.code === 1000) {
                this.getSubmitRespMessage()
                if (this.requestRemarksEditFlag) {
                  this.submitUpdateForm()
                }
              } else {
                this.getSubmitRespMessage(resp.message)
              }
            })
          } else {
            if (onlyUpdatetRemarks) {
              this.submitUpdateForm()
            } else {
              this.$message({
                type: 'error',
                message: 'Notice of Assignment documents and Invoice documents need to be uploaded!'
              })
            }
          }
          break
        case FINANCE_STATUS[2]:
          if (this.$attrs.isFinancier) {
            if (this.nftFormData.mtOneZeroThreeDocuments.length > 0) {
              const mtOneZeroThreeDocuments = this.nftFormData.mtOneZeroThreeDocuments.map(item => {
                return { sysFileId: item.sysFileId, remarks: item.remarks, fileName: item.fileName }
              })
              const payload = {
                id: this.nftFormData.id,
                mtOneZeroThreeDocuments
              }
              /* upload MT103 documents by financer */
              uploadMtOneZeroThreeDocuments(payload).then(resp => {
                if (resp.code === 1000) {
                  if (this.isReceivedInfoChanged) {
                    this.submitUpdateForm()
                  } else {
                    this.getSubmitRespMessage()
                  }
                } else {
                  this.getSubmitRespMessage(resp.message)
                }
              })
            } else {
              this.submitUpdateForm()
            }
          } else {
            this.submitUpdateForm()
          }
          break
        case FINANCE_STATUS[3]:
          this.submitUpdateForm()
          break
        default:
          /* create new request form */
          this.$refs.requestChildForm.submitRequestForm(this.nftFormData.pendingFinanciers)
          break
      }
    },
    setRemarksEditFlag () {
      this.requestRemarksEditFlag = true
    },

    onRefreshFormPage () {
      this.populateFormData()
    },

    onCloseFormPage () {
      Object.assign(this.$data, this.$options.data())
      this.$emit('backToMainListPage')
      this.$refs.otherChildForm.resetFields()
    },
    handleApprove (isApproved) {
      this.$emit('handleApprove', isApproved)
    },
    onCloseSendReminderDialog () {
      this.isOpenSendEmailDialog = false
      this.$refs.sendEmailDialog.resetFields()
    },
    handleSendEmail (emailList) {
      sendReminderEmail(this.$attrs.formId, emailList).then(resp => {
        if (resp?.code === 1000) {
          this.onCloseSendReminderDialog()
        } else {
          this.getSubmitRespMessage(resp.message)
        }
      })
    },
    onCalculateSummary (formData) {
      const data = formData ?? this.$refs.requestChildForm.financeRequestForm
      const { paymentDays, amount } = data
      const payload = []
      if (amount && amount !== 0 && paymentDays && paymentDays !== 0 && this.nftFormData.pendingFinanciers.length > 0) {
        let financers = this.nftFormData.pendingFinanciers
        if (this.$attrs.isFinancier) {
          financers = this.nftFormData.pendingFinanciers.filter(financer => financer.id === this.financeAccountMe.id)
        }
        this.rightSummaryLoading = true
        financers.forEach((finacer, idx) => {
          const item = {
            id: finacer.id,
            paymentDays,
            amount,
            discountRatePercentage: finacer.discountRatePercentage,
            lateChargesPercentage: finacer.lateChargesPercentage
          }
          payload.push(item)
        })
        previewFeesAndCharges(payload).then(resp => {
          if (resp.code === 1000) {
            const list = this.nftFormData?.pendingFinanciers?.length > 0 ? this.nftFormData.pendingFinanciers : this.nftFormData.selectedFinancers
            if (resp?.data?.length > 0 && list.length > 0) {
              this.nftFormData.summaryList = resp?.data
              this.nftFormData.summaryList.forEach((el, index) => {
                el.name = this.nftFormData.pendingFinanciers.filter(financer => financer.id === el.id)[0]?.name
                return el
              })
            } else {

            }
            // this.nftFormData.summaryList = resp?.data
          } else {
            this.getSubmitRespMessage(resp.message)
          }
          this.rightSummaryLoading = false
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../index.scss";
.el-tabs--border-card {
  border: none;
  box-shadow: none;
}
/deep/ .el-tabs__header {
  border: none !important;
  background-color: #fff !important;
}
/deep/ .el-tabs__item {
  border: none !important;
  margin: 0 !important;
  color: #fff !important;
  .el-icon-close {
    background-color: #f56c6c;
    color: #fff;
    padding: 1px;
  }
}
/deep/ .el-tabs__nav {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #82889c;
}
/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #0f376a !important;
  width: 120px;
  padding-left: 25px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-disabled {
  display: none;
}
/deep/ .el-tabs__nav div:last-child {
  width: 50px;
  border-top-right-radius: 15px;
  color: #fff;
  padding-left: 20px;
  background: radial-gradient(circle at 3% 3%, #82889c, #ccd1e1);
  .el-icon-close {
    display: none;
  }
}
/deep/ .el-tabs--border-card > .el-tabs__content {
  padding-top: 0;
  padding-left: 0;
}
</style>
