var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "div-form-main",
    },
    [
      _c(
        "div",
        { staticClass: "div-form-main-btn" },
        [
          _vm.$attrs.isFinancier &&
          _vm.nftFormData.status !== _vm.FINANCE_STATUS[0] &&
          _vm.nftFormData.status !== _vm.FINANCE_STATUS[4]
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { round: "", type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isOpenSendEmailDialog = true
                    },
                  },
                },
                [_vm._v(" Send Reminder Email ")]
              )
            : _vm._e(),
          _vm.$attrs.isFinancier &&
          _vm.nftFormData.status === _vm.FINANCE_STATUS[0]
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { round: "", type: "success", size: "small" },
                  on: { click: () => _vm.$emit("handleApprove", true) },
                },
                [_vm._v(" Approve ")]
              )
            : _vm._e(),
          _vm.$attrs.isFinancier &&
          _vm.nftFormData.status === _vm.FINANCE_STATUS[0]
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { round: "", type: "danger", size: "small" },
                  on: { click: () => _vm.$emit("handleApprove", false) },
                },
                [_vm._v(" Reject ")]
              )
            : _vm._e(),
          ([
            _vm.FINANCE_VIEW.CREATE_FINANCE,
            _vm.FINANCE_VIEW.EDIT_FINANCE,
          ].includes(_vm.view) &&
            !_vm.$attrs.isFinancier &&
            _vm.nftFormData.status !== _vm.FINANCE_STATUS[4]) ||
          (_vm.$attrs.isFinancier &&
            [_vm.FINANCE_STATUS[2], _vm.FINANCE_STATUS[3]].includes(
              _vm.nftFormData.status
            ))
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { round: "", type: "primary", size: "small" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("Submit")]
              )
            : _vm._e(),
          _vm.$attrs.isFinancier &&
          _vm.nftFormData.status === _vm.FINANCE_STATUS[3]
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { round: "", type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.isOpenCompleteDialog = true
                    },
                  },
                },
                [_vm._v("Complete")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticStyle: { "min-width": "100px" },
              attrs: { round: "", type: "second", size: "small" },
              on: { click: _vm.onCloseFormPage },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "div-form-main-container" }, [
        _c(
          "div",
          {
            staticClass: "el-card-is-always-shadow",
            staticStyle: { padding: "20px" },
          },
          [
            _c(
              "div",
              { staticClass: "step-container" },
              [
                _c(
                  "el-steps",
                  {
                    attrs: {
                      active: _vm.activeStepName,
                      "align-center": "",
                      "finish-status": "success",
                    },
                  },
                  _vm._l(_vm.FINANCE_STEPS, function (item) {
                    return _c("el-step", {
                      key: item.name,
                      attrs: { description: item.label },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { width: "100%", "padding-top": "10px" } },
              [
                _c(
                  "div",
                  { staticClass: "cus-tab-container" },
                  _vm._l(_vm.documentTabs, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "tab-item-nav",
                        style: _vm.getTabStyle(item.name),
                        on: {
                          click: function ($event) {
                            return _vm.handleClickTab(item.name)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.label) + " ")]
                    )
                  }),
                  0
                ),
                !_vm.formLoading
                  ? _c(
                      "request-info-form",
                      _vm._g(
                        _vm._b(
                          {
                            ref: "requestChildForm",
                            style: {
                              display:
                                _vm.activeDocUploadTab === "REQUEST_DOC"
                                  ? "block"
                                  : "none",
                            },
                            attrs: {
                              view: _vm.view,
                              status: _vm.nftFormData.status,
                              nftFormData: _vm.nftFormData,
                            },
                            on: {
                              "update:nftFormData": function ($event) {
                                _vm.nftFormData = $event
                              },
                              "update:nft-form-data": function ($event) {
                                _vm.nftFormData = $event
                              },
                              setRemarksEditFlag: _vm.setRemarksEditFlag,
                              onCalculateSummary: _vm.onCalculateSummary,
                              populateFormData: _vm.populateFormData,
                            },
                          },
                          "request-info-form",
                          _vm.$attrs,
                          false
                        ),
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                !_vm.formLoading
                  ? _c(
                      "other-info-form",
                      _vm._b(
                        {
                          ref: "otherChildForm",
                          style: {
                            display:
                              _vm.activeDocUploadTab === "OTHER_DOC"
                                ? "block"
                                : "none",
                          },
                          attrs: {
                            view: _vm.view,
                            status: _vm.nftFormData.status,
                            nftRespDetails: _vm.nftRespDetails,
                            nftFormData: _vm.nftFormData,
                            isReceivedInfoChanged: _vm.isReceivedInfoChanged,
                          },
                          on: {
                            "update:nftFormData": function ($event) {
                              _vm.nftFormData = $event
                            },
                            "update:nft-form-data": function ($event) {
                              _vm.nftFormData = $event
                            },
                            "update:isReceivedInfoChanged": function ($event) {
                              _vm.isReceivedInfoChanged = $event
                            },
                            "update:is-received-info-changed": function (
                              $event
                            ) {
                              _vm.isReceivedInfoChanged = $event
                            },
                            populateFormData: _vm.populateFormData,
                          },
                        },
                        "other-info-form",
                        _vm.$attrs,
                        false
                      )
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "div-form-main-right-summary-border-none" },
          [
            !_vm.formLoading && !_vm.rightSummaryLoading
              ? _c(
                  "right-financer-info",
                  _vm._b(
                    {
                      attrs: {
                        isViewOnly:
                          _vm.isView ||
                          [
                            _vm.FINANCE_STATUS[1],
                            _vm.FINANCE_STATUS[2],
                            _vm.FINANCE_STATUS[3],
                            _vm.FINANCE_STATUS[4],
                          ].includes(_vm.nftFormData.status) ||
                          _vm.$attrs.isFinancier,
                        selectedFinancers: _vm.nftFormData.pendingFinanciers,
                        isOpenSelectFinancerDialog:
                          _vm.isOpenSelectFinancerDialog,
                        rightSummaryLoading: _vm.rightSummaryLoading,
                        status: _vm.nftFormData.status,
                        view: _vm.view,
                        financier: _vm.nftFormData.financier,
                        supplier: _vm.nftFormData.supplier,
                        summaryList: _vm.nftFormData.summaryList,
                        nftRespDetails: _vm.nftRespDetails,
                      },
                      on: {
                        "update:selectedFinancers": function ($event) {
                          return _vm.$set(
                            _vm.nftFormData,
                            "pendingFinanciers",
                            $event
                          )
                        },
                        "update:selected-financers": function ($event) {
                          return _vm.$set(
                            _vm.nftFormData,
                            "pendingFinanciers",
                            $event
                          )
                        },
                        "update:isOpenSelectFinancerDialog": function ($event) {
                          _vm.isOpenSelectFinancerDialog = $event
                        },
                        "update:is-open-select-financer-dialog": function (
                          $event
                        ) {
                          _vm.isOpenSelectFinancerDialog = $event
                        },
                        "update:rightSummaryLoading": function ($event) {
                          _vm.rightSummaryLoading = $event
                        },
                        "update:right-summary-loading": function ($event) {
                          _vm.rightSummaryLoading = $event
                        },
                        onCalculateSummary: _vm.onCalculateSummary,
                        "update:summaryList": function ($event) {
                          return _vm.$set(
                            _vm.nftFormData,
                            "summaryList",
                            $event
                          )
                        },
                        "update:summary-list": function ($event) {
                          return _vm.$set(
                            _vm.nftFormData,
                            "summaryList",
                            $event
                          )
                        },
                      },
                    },
                    "right-financer-info",
                    _vm.$attrs,
                    false
                  )
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("dialog-link-financer", {
        attrs: {
          isVisited: _vm.isOpenSelectFinancerDialog,
          selectedFinancers: _vm.nftFormData.pendingFinanciers,
        },
        on: {
          "update:selectedFinancers": function ($event) {
            return _vm.$set(_vm.nftFormData, "pendingFinanciers", $event)
          },
          "update:selected-financers": function ($event) {
            return _vm.$set(_vm.nftFormData, "pendingFinanciers", $event)
          },
          onCalculateSummary: _vm.onCalculateSummary,
          onCloseFinancerDialog: function ($event) {
            _vm.isOpenSelectFinancerDialog = false
          },
        },
      }),
      _c("dialog-complete-finance", {
        attrs: {
          dialogVisible: _vm.isOpenCompleteDialog,
          entityData: _vm.nftFormData,
        },
        on: {
          onCloseFinancerDialog: function ($event) {
            _vm.isOpenCompleteDialog = false
          },
          getSubmitRespMessage: _vm.getSubmitRespMessage,
        },
      }),
      _c("dialog-send-reminder-email", {
        ref: "sendEmailDialog",
        attrs: {
          dialogVisible: _vm.isOpenSendEmailDialog,
          defaultEmail: _vm.nftFormData.emails,
          title: "Send Reminder Email",
        },
        on: {
          handleSendReminderDialogClose: _vm.onCloseSendReminderDialog,
          handleSendEmail: _vm.handleSendEmail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }